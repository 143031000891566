import {WithStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton/IconButton'
import {RouteComponentProps, withRouter} from 'react-router'
import Typography from '@material-ui/core/Typography/Typography'
import {gatewayClient} from '../client/GatewayClient'
import {HttpError} from '../utils/exceptions'
import Form from '../components/informed/Form'
import {FormApi} from 'informed'
import {TextField} from '../components/informed/TextField'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'

const styles = () =>
  createStyles({
    button: {
      margin: 8,
    },
  })

const initialForm = {
  password: '',
  username: '',
  confirm: '',
}

type FormData = typeof initialForm

const initialState = {
  snackbar: false,
}

type State = {
  snackbar: boolean
}

type Props = WithStyles<typeof styles> & RouteComponentProps<{}>

class RegisterPage extends React.Component<Props, State> {
  public readonly state: State = initialState

  private formApi?: FormApi<FormData>

  public render() {
    const classes = this.props.classes
    return (
      <div>
        <Typography variant="h5">注册</Typography>
        <Form
          getApi={formApi => (this.formApi = formApi)}
          initialValues={initialForm}
          onSubmit={this.handleFormSubmit}
        >
          <TextField
            field="username"
            label="用户名"
            fullWidth={true}
            margin="dense"
          />
          <TextField
            field="password"
            type="password"
            label="密码"
            fullWidth={true}
            margin="dense"
          />
          <TextField
            field="confirm"
            type="password"
            label="确认密码"
            fullWidth={true}
            margin="dense"
          />
          <Button className={classes.button} onClick={this.handleBackClick}>
            返回
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => this.formApi && this.formApi.submitForm()}
          >
            注册
          </Button>
        </Form>
        <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          open={this.state.snackbar}
          autoHideDuration={1000}
          onClose={this.handleBackClick}
          message={<span>注册成功</span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.handleBackClick}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )
  }

  private handleBackClick = () => {
    this.props.history.push('/')
  }

  private handleFormSubmit = async (param: FormData) => {
    try {
      await gatewayClient.signUp(param)

      this.setState({snackbar: true})
    } catch (e) {
      if (e instanceof HttpError) {
        const formApi = this.formApi
        formApi && formApi.setError(e.message.field, e.message.error)
      }
    }
  }
}

export default withRouter(withStyles(styles)(RegisterPage))
