import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import {UserInfo} from '../../models/UserInfo'
import DrawerLayout from '../../components/layouts/DrawerLayout'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import ZukVncInstanceTab from './ZukVncInstanceTab'
import ZukVncTaskTab from './ZukVncTaskTab'
import ZukVncStoppedTaskTab from './ZukVncStoppedTaskTab'

const styles = createStyles({
  content: {
    margin: 8,
  },
})

const initState: State = {
  tab: 0,
}

interface ComponentProps {
  user: UserInfo
}

type Props = WithStyles<typeof styles> & ComponentProps

interface State {
  tab: number
}

class ZukVncAdminPage extends React.Component<Props, State> {
  readonly state = initState

  public render() {
    return (
      <DrawerLayout user={this.props.user}>
        <AppBar position="static">
          <Tabs value={this.state.tab} onChange={this.onTabChange}>
            <Tab label="实例" />
            <Tab label="任务" />
            <Tab label="录像" />
          </Tabs>
        </AppBar>
        <div className={this.props.classes.content}>
          {this.showTab(this.state.tab)}
        </div>
      </DrawerLayout>
    )
  }

  private showTab = (index: number) => {
    switch (index) {
      case 0:
        return <ZukVncInstanceTab user={this.props.user} />
      case 1:
        return <ZukVncTaskTab user={this.props.user} />
      case 2:
        return <ZukVncStoppedTaskTab user={this.props.user} />
    }
  }

  private onTabChange = (event: object, value: number) =>
    this.setState({tab: value})
}

export default withStyles(styles)(ZukVncAdminPage)
