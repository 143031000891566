import {GatewayClient, GetAllModuleApplicationsRequest} from './GatewayClient'
import {get, post} from '../utils/fetch'
import {
  SIGN_IN_URL,
  MODULES_URL,
  VALIDATE_TOKEN_URL,
  SIGN_UP_URL,
} from '../variables/url'
import {Principal} from '../models/Principal'
import {ModuleApplication} from '../models/ModuleApplication'

class GatewayClientImpl implements GatewayClient {
  public signIn(param: {username: string; password: string}): Promise<string> {
    return post(SIGN_IN_URL, param).then(v => v.token)
  }

  public signUp(param: {username: string; password: string}): Promise<{}> {
    return post(SIGN_UP_URL, param).then(() => ({}))
  }

  public validateToken(token: string): Promise<Principal> {
    return get(VALIDATE_TOKEN_URL(token))
  }

  public getAllModuleApplications(
    option: GetAllModuleApplicationsRequest,
  ): Promise<ModuleApplication[]> {
    return get(MODULES_URL, option)
  }
}

export const gatewayClientImpl = new GatewayClientImpl()
