import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import {UserInfo} from '../models/UserInfo'
import DrawerLayout from '../components/layouts/DrawerLayout'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import ZukVncAdminTab from './zukvnc-admin/ZukVncStoppedTaskTab'
import ZukVncOperationTab from './ZukVncOperationTab'

const styles = createStyles({
  content: {
    margin: 8,
  },
})

const initState: State = {
  tab: 0,
}

interface ComponentProps {
  user: UserInfo
}

type Props = WithStyles<typeof styles> & ComponentProps

interface State {
  tab: number
}

class ZukVncPage extends React.Component<Props, State> {
  readonly state = initState

  public render() {
    return (
      <DrawerLayout user={this.props.user}>
        <AppBar position="static">
          <Tabs value={this.state.tab} onChange={this.onTabChange}>
            <Tab label="操作" />
          </Tabs>
        </AppBar>
        <div className={this.props.classes.content}>
          {this.showTab(this.state.tab)}
        </div>
      </DrawerLayout>
    )
  }

  private showTab = (index: number) => {
    switch (index) {
      case 0:
        return <ZukVncOperationTab user={this.props.user} />
      case 1:
        return <ZukVncAdminTab user={this.props.user} />
    }
  }

  private onTabChange = (event: object, value: number) =>
    this.setState({tab: value})
}

export default withStyles(styles)(ZukVncPage)
