import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import {UserInfo} from '../models/UserInfo'
import {drawerWidth} from '../variables/style'
import DrawerLayout from '../components/layouts/DrawerLayout'
import {ModuleApplication} from '../models/ModuleApplication'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {gatewayClient} from '../client/GatewayClient'

const styles = createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
})

const initState = {
  moduleApplications: [] as ModuleApplication[],
}

interface ComponentProps {
  user: UserInfo
}

type State = typeof initState

type Props = WithStyles<typeof styles> & ComponentProps

class ModulePage extends React.Component<Props, State> {
  readonly state = initState

  public componentDidMount() {
    const load = async () => {
      const gatewayRole = this.props.user.principal.roles.gateway
      const option = Object.assign(
        {},
        gatewayRole === 'ADMIN'
          ? {}
          : {applicantId: this.props.user.principal.id},
      )
      const moduleApplications = await gatewayClient.getAllModuleApplications(
        option,
      )
      this.setState({moduleApplications})
    }

    load()
  }

  public render() {
    return (
      <DrawerLayout user={this.props.user}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>编号</TableCell>
              <TableCell>申请人</TableCell>
              <TableCell>模块名</TableCell>
              <TableCell>描述</TableCell>
              <TableCell>Url</TableCell>
              <TableCell>申请时间</TableCell>
              <TableCell>状态</TableCell>
              <TableCell>处理人</TableCell>
              <TableCell>处理时间</TableCell>
              <TableCell>理由</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.moduleApplications.map(moduleApplication => (
              <TableRow key={moduleApplication.id}>
                <TableCell>{moduleApplication.id}</TableCell>
                <TableCell>{moduleApplication.applicantId}</TableCell>
                <TableCell>{moduleApplication.moduleName}</TableCell>
                <TableCell>{moduleApplication.description}</TableCell>
                <TableCell>{moduleApplication.url}</TableCell>
                <TableCell>{moduleApplication.createdTime}</TableCell>
                <TableCell>{moduleApplication.status}</TableCell>
                <TableCell>{moduleApplication.approverId}</TableCell>
                <TableCell>{moduleApplication.processedTime}</TableCell>
                <TableCell>{moduleApplication.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DrawerLayout>
    )
  }
}

export default withStyles(styles)(ModulePage)
