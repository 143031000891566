import * as moment from 'moment'

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp)
  return date.toLocaleString()
}

export function readableDuration(duration: number) {
  const d = moment.duration(duration)
  return d.locale('zh-cn').humanize()
}
