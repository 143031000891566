import {
  Link,
  LinkProps,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import ListItem, {ListItemProps} from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import React from 'react'
import {LocationDescriptor} from 'history'

interface Props extends RouteComponentProps<{}> {
  to: string
  primary: string
}

class ListItemLink extends React.Component<Props> {
  render() {
    const {primary, history, to} = this.props
    return (
      <ListItem button onClick={() => history.push(to)}>
        <ListItemText primary={primary} />
      </ListItem>
    )
  }
}

export default withRouter(ListItemLink)
