export class HttpError extends Error {
  public readonly status: number
  public readonly message: any

  public constructor(message: any, status: number) {
    super('Http Error')
    this.status = status
    this.message = message
  }
}
