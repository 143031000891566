import {asField, FieldProps, FormValue, FormValues} from 'informed'
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField/TextField'
import React from 'react'

export const TextField = asField<
  FieldProps<FormValue<string | number>, FormValues> & MuiTextFieldProps,
  FormValue<string | number>
>(({fieldState, fieldApi, forwardedRef, onChange, onBlur, field, ...rest}) => {
  const {value} = fieldState
  const {setValue, setTouched, setError} = fieldApi

  const errorProps = fieldState.error
    ? {helperText: fieldState.error, error: true}
    : {}

  return (
    <MuiTextField
      {...rest}
      {...errorProps}
      name={field}
      inputRef={forwardedRef}
      onChange={e => {
        setError(undefined)
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={e => {
        setTouched(true)
        if (onBlur) {
          onBlur(e)
        }
      }}
      value={value || ''}
    />
  )
})
