import React from 'react'
import {Form, FormProps, FormValues} from 'informed'

class WrappedForm<V = FormValues> extends React.Component<FormProps<V>> {
  public render() {
    const {children, ...rest} = this.props

    return (
      <Form {...rest} style={{width: '100%'}}>
        {children}
      </Form>
    )
  }
}

export default WrappedForm
