import React from 'react'
import {UserInfo} from '../../models/UserInfo'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {zukVncClient, ListTasksResponse} from '../../client/ZukVncClient'
import {VncTask} from '../../models/VncTask'
import Button from '@material-ui/core/Button/Button'
import {formatTimestamp} from '../../utils/time'
import {List} from 'immutable'

interface ComponentProps {
  user: UserInfo
}

const initState: State = {
  open: false,
  tasks: List.of(),
}

interface State {
  open: boolean
  tasks: List<VncTask>
}

type Props = ComponentProps

class ZukVncTaskTab extends React.Component<Props, State> {
  readonly state = initState

  componentDidMount() {
    zukVncClient
      .listAllTasks(50, 0, false)
      .then((res: ListTasksResponse) =>
        this.setState({tasks: List(res.data.filter(task => task.startTime))}),
      )
  }

  onClickClose = (task: VncTask, index: number) => () => {
    zukVncClient.stopTask(task.id)
    this.setState({tasks: this.state.tasks.splice(index, 1)})
  }

  task2row = (task: VncTask, index: number) => {
    return (
      <TableRow key={task.id}>
        <TableCell>{task.id}</TableCell>
        <TableCell>{task.userId}</TableCell>
        <TableCell>{formatTimestamp(task.startTime)}</TableCell>
        <TableCell>运行中</TableCell>
        <TableCell>{task.recordingName ? '录制中' : '未录制'}</TableCell>
        <TableCell>
          <Button color="primary" onClick={this.onClickClose(task, index)}>
            关闭
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  public render() {
    return (
      <div>
        <h3>Zukvnc任务信息</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>任务编号</TableCell>
              <TableCell>用户</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell>任务状态</TableCell>
              <TableCell>录制状态</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.state.tasks.map(this.task2row)}</TableBody>
        </Table>
      </div>
    )
  }
}

export default ZukVncTaskTab
