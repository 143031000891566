import {SagaIterator} from 'redux-saga'
import {takeEvery} from 'redux-saga/effects'
import {gatewayClient} from '../client/GatewayClient'
import {Action} from 'typescript-fsa'
import {bindAsyncAction} from '../utils/async'

// const loginWorker = bindAsyncAction(login)(
//   async (params) => {
//     const token = await gatewayClient.signIn(params);
//     const principal = await gatewayClient.validateToken(`Bearer ${token}`);
//     const userInfo = {token, principal};
//     localStorage.setItem("user", JSON.stringify(userInfo));
//     return userInfo;
//   }
// );

export function* rootSaga(): SagaIterator {
  yield* [
    // takeEvery(login.started.type, (action: Action<any>) => loginWorker(action.payload))
  ]
}
