import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import HomePage from './pages/HomePage'
import {RootState} from './redux/reducers'
import {connect} from 'react-redux'
import {Option} from 'ts-option'
import {UserInfo} from './models/UserInfo'
import DashboardPage from './pages/DashboardPage'
import ModulePage from './pages/ModulePage'
import RegisterPage from './pages/RegisterPage'
import ZukVncPage from './pages/ZukVncPage'
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import ZukVncAdminPage from './pages/zukvnc-admin/ZukVncAdminPage'

const mapStateToProps = (state: RootState) => ({
  user: state.user,
})

interface StateProps {
  user: Option<UserInfo>
}

type Props = StateProps

class App extends Component<Props, {}> {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          {this.props.user.match({
            some: user => (
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  render={() => <DashboardPage user={user} />}
                />
                <Route
                  path="/modules"
                  exact={true}
                  render={() => <ModulePage user={user} />}
                />
                <Route
                  path="/zukvnc"
                  exact={true}
                  render={() => <ZukVncPage user={user} />}
                />
                <Route
                  path="/zukvnc-admin"
                  exact={true}
                  render={() => <ZukVncAdminPage user={user} />}
                />
              </Switch>
            ),
            none: () => (
              <Switch>
                <Route
                  path="/register"
                  exact={true}
                  render={() => <RegisterPage />}
                />
                <Route path="/" render={() => <HomePage />} />
              </Switch>
            ),
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(App)
