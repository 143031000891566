import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import Drawer from '@material-ui/core/Drawer/Drawer'
import List from '@material-ui/core/List/List'
import {drawerWidth} from '../../variables/style'
import ListItemLink from '../../components/ListItemLink'
import {signOut} from '../../redux/actions'
import {connect} from 'react-redux'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import ListItem from '@material-ui/core/ListItem/ListItem'
import {UserInfo} from '../../models/UserInfo'

const styles = createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
})

const mapDispatchToProps = {
  signOut,
}

interface ComponentProps {
  user: UserInfo
}

type Props = WithStyles<typeof styles> &
  ComponentProps &
  typeof mapDispatchToProps

class DrawerLayout extends React.Component<Props, {}> {
  private handleLogoutClick = () => {
    this.props.signOut({})
    localStorage.removeItem('user')
  }

  public render() {
    const {classes} = this.props
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          classes={{paper: classes.drawerPaper}}
          variant="permanent"
        >
          <List>
            <ListItem component="a" onClick={this.handleLogoutClick} button>
              <ListItemText primary="注销" />
            </ListItem>
            <ListItemLink primary="申请记录" to="/modules" />
            <ListItemLink primary="VNC" to="/zukvnc" />
            {this.props.user.principal.roles.zukvnc === 'ADMIN' && (
              <ListItemLink primary="VNC管理" to="/zukvnc-admin" />
            )}
          </List>
        </Drawer>
        <div className={classes.content}>{this.props.children}</div>
      </div>
    )
  }
}

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(DrawerLayout),
)
