import {InstanceInfo} from '../models/TaskInfo'
import {del, get, post} from '../utils/fetch'
import {
  GENERATE_URL_URL,
  LIST_ALL_INSTANCE_URL,
  LIST_ALL_TASKS_URL,
  START_RECORD_URL,
  STOP_RECORD_URL,
  STOP_TASK_URL,
} from '../variables/url'

import {
  ListTasksResponse,
  StartRecordingRequest,
  ZukVncClient,
} from './ZukVncClient'

class ZukVncClientImpl implements ZukVncClient {
  public startRecord(param: StartRecordingRequest): Promise<{id: number}> {
    return post(START_RECORD_URL, param)
  }

  public stopRecord(param: {display: number}): Promise<{}> {
    return post(STOP_RECORD_URL, param)
  }

  public listAllInstances(): Promise<InstanceInfo[]> {
    return get(LIST_ALL_INSTANCE_URL)
  }

  public listAllTasks(
    pageSize: number,
    pageNum: number,
    stopped: boolean,
  ): Promise<ListTasksResponse> {
    return get(LIST_ALL_TASKS_URL, {
      pageSize,
      pageNum,
      stopped,
    })
  }

  public stopTask(taskId: number): Promise<{}> {
    return del(STOP_TASK_URL(taskId))
  }

  public generateUrl(
    taskId: number,
    recordName: string,
    expireTime: number,
  ): Promise<{url: string}> {
    return post(GENERATE_URL_URL(taskId, recordName), {expireTime})
  }
}

export const zukVncClientImpl = new ZukVncClientImpl()
