import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import {UserInfo} from '../models/UserInfo'
import DrawerLayout from '../components/layouts/DrawerLayout'

const styles = createStyles({
  root: {
    display: 'flex',
  },
})

interface ComponentProps {
  user: UserInfo
}

type Props = WithStyles<typeof styles> & ComponentProps

class DashboardPage extends React.Component<Props, {}> {
  public render() {
    return (
      <DrawerLayout user={this.props.user}>
        <Typography variant="h5">
          欢迎，{this.props.user.principal.username}
        </Typography>
      </DrawerLayout>
    )
  }
}

export default withStyles(styles)(DashboardPage)
