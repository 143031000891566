import React from 'react'
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {appReducer} from './redux/reducers'
import {applyMiddleware, createStore} from 'redux'
import sagaMiddlewareFactory from 'redux-saga'
import {rootSaga} from './redux/saga'
import {composeWithDevTools} from 'redux-devtools-extension'
const fundebug = require('fundebug-javascript')
fundebug.apikey =
  '4b65bb84fe856853e45ec05a53b591cb9a63c8a3eea41d045b6579cda860b8d6'
require('fundebug-revideo')

const sagaMiddleware = sagaMiddlewareFactory()

export const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
