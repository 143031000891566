import {VncTask} from '../models/VncTask'
import {InstanceInfo} from '../models/TaskInfo'
import {zukVncClientImpl} from './ZukVncClientImpl'

export interface StartRecordingRequest {
  display: number
  url: string
  recordName: string
  callback: string
}

export interface ListTasksResponse {
  data: VncTask[]
  totalCount: number
}

export interface ZukVncClient {
  stopTask(taskId: number): Promise<{}>

  startRecord(param: StartRecordingRequest): Promise<{id: number}>

  stopRecord(param: {display: number}): Promise<{}>

  listAllInstances(): Promise<InstanceInfo[]>

  listAllTasks(
    pageSize: number,
    pageNum: number,
    stopped: boolean,
  ): Promise<ListTasksResponse>

  generateUrl(
    taskId: number,
    recordName: string,
    expireTime: number,
  ): Promise<{url: string}>
}

export const zukVncClient: ZukVncClient = zukVncClientImpl
