import {gatewayClientImpl} from './GatewayClientImpl'
import {Principal} from '../models/Principal'
import {ModuleApplication} from '../models/ModuleApplication'

export interface GetAllModuleApplicationsRequest {
  applicantId?: string
  status?: 'WAITING' | 'ACCEPTED' | 'REJECTED'
}

export interface GatewayClient {
  signIn(param: {username: string; password: string}): Promise<string>

  signUp(param: {username: string; password: string}): Promise<{}>

  validateToken(token: string): Promise<Principal>

  getAllModuleApplications(
    option: GetAllModuleApplicationsRequest,
  ): Promise<ModuleApplication[]>
}

export const gatewayClient: GatewayClient = gatewayClientImpl
