import React from 'react'
import {UserInfo} from '../../models/UserInfo'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {InstanceInfo} from '../../models/TaskInfo'
import {zukVncClient} from '../../client/ZukVncClient'
import {formatTimestamp} from '../../utils/time'

interface ComponentProps {
  user: UserInfo
}

const initState: State = {
  instanceInfos: [],
}

interface State {
  instanceInfos: InstanceInfo[]
}

type Props = ComponentProps

class ZukVncAdminTab extends React.Component<Props, State> {
  readonly state = initState

  componentDidMount() {
    zukVncClient
      .listAllInstances()
      .then(instanceInfos => this.setState({instanceInfos}))
  }

  public render() {
    return (
      <div>
        <h3>空闲ECS实例信息</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>地区</TableCell>
              <TableCell>实例编号</TableCell>
              <TableCell>EC2 ID</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>创建时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.instanceInfos.map(instanceInfo => (
              <TableRow key={instanceInfo.instance.taskArn}>
                <TableCell>{instanceInfo.region}</TableCell>
                <TableCell>{instanceInfo.instance.taskArn}</TableCell>
                <TableCell>{instanceInfo.instance.ec2InstanceId}</TableCell>
                <TableCell>{instanceInfo.instance.ip}</TableCell>
                <TableCell>
                  {formatTimestamp(instanceInfo.instance.createdTime)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ZukVncAdminTab
